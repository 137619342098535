<template>
    <div class="landscape-person-select-body">
        <div class="landscape-person-select-body-left">
            <div>
                <div>角色信息</div>
            </div>
            <div>
                <img :src="personUrl">
            </div>
           <div>
                <div>{{personName}}</div>
            </div>
        </div>
        <div class="landscape-person-select-body-right">
            <div>
                <div>
                    <img src="https://vr7niu.16fw.cn/person-select/male.png">
                    <img src="https://vr7niu.16fw.cn/person-select/female.png">
                </div>
            </div>
            <div>
                <div class="content-item"  :class="{active:item.checked}"  v-for="item,index in avatarList" v-bind:key="index">
                    <div @click="chooseImg(index)">
                        <img :src="item.avatarUrl">
                    </div>
                </div>
            </div>
            <div>
                <div @click="confirmSelect">确认选择</div>
            </div>
        </div>
    </div>
</template>

<script>
import {getQueryVariable} from "@/js/tool";
export default {
    name: 'personSelectLandScape',
    data(){
        return{
            avatarList:[{
                id: 3,
                name:"角色3",
                avatarUrl: "https://vr7niu.16fw.cn/person-select/Mesh3.png",
                personUrl: "https://vr7niu.16fw.cn/person-select/3.png",
                personGlb: "https://vr7niu.16fw.cn/person-select/Mesh3.glb",
                checked: true
            },{
                id: 1,
                name:"角色1",
                avatarUrl: "https://vr7niu.16fw.cn/person-select/Mesh1.png",
                personUrl: "https://vr7niu.16fw.cn/person-select/1.png",
                personGlb: "https://vr7niu.16fw.cn/person-select/Mesh1.glb",
                checked: false
            },{
                id: 2,
                name:"角色2",
                avatarUrl: "https://vr7niu.16fw.cn/person-select/Mesh2.png",
                personUrl: "https://vr7niu.16fw.cn/person-select/2.png",
                personGlb: "https://vr7niu.16fw.cn/person-select/Mesh2.glb",
                checked: false
            },{
                id: 4,
                name:"角色4",
                avatarUrl: "https://vr7niu.16fw.cn/person-select/Mesh4.png",
                personUrl: "https://vr7niu.16fw.cn/person-select/4.png",
                personGlb: "https://vr7niu.16fw.cn/person-select/Mesh4.glb",
                checked: false
            },{
                id: 5,
                name:"角色5",
                avatarUrl: "https://vr7niu.16fw.cn/person-select/Mesh5.png",
                personUrl: "https://vr7niu.16fw.cn/person-select/5.png",
                personGlb: "https://vr7niu.16fw.cn/person-select/Mesh5.glb",
                checked: false
            }],
            personName: "角色3",
            personUrl: "https://vr7niu.16fw.cn/person-select/3.png",
            personGlb: "https://vr7niu.16fw.cn/person-select/Mesh3.glb"
        }
    },
    created(){
        document.documentElement.style.fontSize = document.documentElement.clientWidth / 750 * 100 + 'px';
    },
    mounted(){

    },
    methods:{
        chooseImg(index){
            this.avatarList.forEach((e,k)=>{
                if(index == k){
                    e.checked = true
                    this.personUrl = e.personUrl
                    this.personName = e.name
                    this.personGlb = e.personGlb
                }else{
                    e.checked = false
                }
            })
        },
        confirmSelect(){
            // console.log(234)
            this.$emit('showLoading');
            const tem_id = getQueryVariable("tem_id");
            console.log(tem_id)
            if(tem_id != 44 && tem_id != 43 ){
                const glb = this.personGlb
                window.game.createCharacter(glb)
            }
            
            
        }
    }
}
</script>


<style>
    html,body {
        height: 100%;
        margin: 0px;
        padding: 0px;
        background-color: #04081A;
    }
    .landscape-person-select-body{
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 9999;
        background-color: #04081A;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .landscape-person-select-body > .landscape-person-select-body-left{
        width: 1.9rem;
        height: 3.1rem;
    }
    .landscape-person-select-body > .landscape-person-select-body-left > div:first-child{
        height: 0.2rem;
        width: 100%;
    }
    .landscape-person-select-body > .landscape-person-select-body-left > div:first-child > div{
        color: white;
        font-size: 0.2rem;
        line-height: 0.2rem;
        height: 0.2rem;
    }
    .landscape-person-select-body > .landscape-person-select-body-left > div:nth-child(2){
        height: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .landscape-person-select-body > .landscape-person-select-body-left > div:nth-child(2) > img{
        width: 1.02rem;
        height: 2.43rem;
        object-fit: cover;
    }
    .landscape-person-select-body > .landscape-person-select-body-left > div:nth-child(3){
        width: 100%;
        height: 0.38rem;
    }
    .landscape-person-select-body > .landscape-person-select-body-left > div:nth-child(3) > div{
        width: 1.7rem;
        height: 0.38rem;
        background: rgb(16,21,40);
        box-shadow: inset 0 0.5px 0.5px hsl(0deg 0% 100% / 24%);
        font-size: 0.14rem;
        color: white;
        text-align: center;
        line-height: 0.38rem;
        border-radius: 0.1rem;
    }
    .landscape-person-select-body > .landscape-person-select-body-right{
        width: 2.8rem;
        height: 3.1rem;
    }
    .landscape-person-select-body > .landscape-person-select-body-right > div:first-child{
        width: 100%;
        height: 0.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .landscape-person-select-body > .landscape-person-select-body-right > div:first-child > div{
        width: 1rem;
        height: 0.4rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .landscape-person-select-body > .landscape-person-select-body-right > div:first-child > div > img{
        width: 0.4rem;
        height: 0.4rem;
    }
    .landscape-person-select-body > .landscape-person-select-body-right > div:nth-child(2){
        width: 3.2rem;
        height: 2.1rem;
    }
    .landscape-person-select-body > .landscape-person-select-body-right > div:nth-child(2) > .content-item{
        display: inline-block;
        float: left;
        margin-top: 0.18rem;
        margin-left: 0.18rem;
        width: 0.82rem;
        height: 0.82rem;
        border-radius: 0.04rem;
        background: rgb(16,21,40);
        box-shadow: inset 0 0.5px 0.5px hsl(0deg 0% 100% / 24%);
        border: 0.02rem solid #04081A;
    }
    .landscape-person-select-body > .landscape-person-select-body-right > div:nth-child(2) > .content-item.active{
        border: 0.02rem solid #00FFF6;
    
    }
    .landscape-person-select-body > .landscape-person-select-body-right > div:nth-child(2) > .content-item > div{ 
        display:flex;
        width: 0.82rem;
        height: 0.82rem;
    }
    .landscape-person-select-body > .landscape-person-select-body-right > div:nth-child(2) > .content-item > div> img{
        width: 0.82rem;
        height: 0.82rem;
        object-fit: cover;
    }
    .landscape-person-select-body > .landscape-person-select-body-right > div:nth-child(3){
        width: 100%;
        height: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .landscape-person-select-body > .landscape-person-select-body-right > div:nth-child(3) > div{ 
        width: 2.02rem;
        height: 0.61rem;
        background: url('https://vr7niu.16fw.cn/person-select/c-button-l.png') no-repeat center center;
        background-size: cover;
        text-align: center;
        line-height: 0.61rem;
        font-size: 0.15rem;
        color: #00FFF6;
    }
</style>
